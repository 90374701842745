<template>
  <div
    class="w-full min-h-16 grid grid-cols-10 mt-2 bg-table border"
    :class="{
      'border-success': !notification.read,
      'w-full': !responsiveBreakpointSm,
    }"
  >
    <div
      class="col-span-2 border border-table flex items-center justify-center cursor-pointer"
    >
      <hit-icon
        :icon="notification.type.toLowerCase()"
        size="lg"
        color="text-panel"
      />
    </div>
    <div
      class="col-span-7 border border-l-0 border-table p-1"
      @click="handleClick"
    >
      <p class="text-md font-bold">
        {{ t(notification.title) }}
      </p>
      <p
        v-if="notification.body_is_html"
        v-html="convertNotificationBody(notification.body)"
      />
      <p v-else>
        {{ convertNotificationBody(notification.body) }}
      </p>
      <p class="flex justify-end text-[0.65rem]">
        <hit-date
          :value="new Date(notification.deposit_date)"
          :show-only-time="showOnlyTime"
        />
      </p>
    </div>
    <div
      class="col-span-1 border border-l-0 border-table flex items-center justify-center cursor-pointer"
    >
      <hit-icon
        :clickable="true"
        icon="clear"
        size="lg"
        @click="$emit('clear', notification.notification_id)"
      />
    </div>
  </div>
</template>
<script>
import HitIcon from '../icon/HitIcon.vue';
import {useI18n} from 'vue-i18n';
import HitBreakpointsMixin from '@hit/components/src/mixins/breakpoints/HitBreakpointsMixin';
import HitDate from '../date/HitDate.vue';
export default {
  name: 'HitNotification',
  components: {
    HitIcon,
    HitDate,
  },
  mixins: [HitBreakpointsMixin],
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  computed: {
    showOnlyTime() {
      const today = new Date();
      const notificationDate = new Date(this.notification.deposit_date);
      return (
        today.getDate() === notificationDate.getDate() &&
        today.getMonth() === notificationDate.getMonth() &&
        today.getFullYear() === notificationDate.getFullYear()
      );
    },
    isClickable() {
      return 'click_event' in this.notification.body;
    },
  },
  methods: {
    convertNotificationBody(notificationBody) {
      if (typeof notificationBody === 'string') {
        // This can either be an i18n key or a string that has to be displayed
        return this.t(notificationBody);
      } else {
        return this.t(notificationBody.message, notificationBody?.payload);
      }
    },

    handleClick() {
      if (!this.isClickable) return;
      const clickData = this.notification.body.click_event;
      switch (clickData.type) {
        case 'redirect':
          this.$router.push({
            name: clickData.target.name,
            params: clickData.target.params,
            query: clickData.target.query,
          });
          break;
        default:
          throw Error(`Unsupported click event type ${clickData.type}`);
      }
      console.log('Handle click on item');
    },
  },
};
</script>
