<template>
  <hit-modal popup-size="large">
    <vue-pdf-app
      :config="pdfViewerConfig"
      :pdf="pdfFileBuffer"
      :file-name="pdfFileName"
      style="height: 85vh"
      page-scale="page-height"
    />
  </hit-modal>
</template>

<script>
import VuePdfApp from 'vue3-pdf-app';
import {HitModal} from '@hit/components/src';

export default {
  name: 'HitPdfPreviewModal',
  components: {
    HitModal,
    VuePdfApp,
  },
  props: {
    /**
     * PDF stream we want to display in the viewer
     */
    pdfFileBuffer: {
      type: ArrayBuffer,
      required: true,
    },

    /**
     * Name of the PDF if the user downloads it via the toolbar
     */
    pdfFileName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pdfViewerConfig: {
        toolbar: {
          toolbarViewerRight: {
            viewBookmark: false,
            openFile: false,
          },
        },
      },
    };
  },
};
</script>
