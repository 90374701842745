<template>
  <hit-card
    :class="[
      selected ? 'border-accent' : 'border-table',
      'border flex flex-col cursor-pointer w-full mb-1 pb-2',
    ]"
    :display-shadow="false"
  >
    <div
      v-if="showZone1"
      class="card-line text-xs"
    >
      <slot
        name="dataListZone1"
        :form-data="item"
        :value="zone1Value"
      >
        {{
          zone1Value
        }}
      </slot>
    </div>
    <div
      v-if="showZone2"
      :class="[secondZoneBold ? 'font-bold text-sm' : 'text-xs', 'card-line']"
    >
      <slot
        name="dataListZone2"
        :form-data="item"
        :value="zone2Value"
      >
        {{
          zone2Value
        }}
      </slot>
    </div>
    <div
      v-if="showZone3"
      class="card-line text-xs"
    >
      <slot
        name="dataListZone3"
        :form-data="item"
        :value="zone3Value"
      >
        {{
          zone3Value
        }}
      </slot>
    </div>
    <div
      v-if="item?.tags?.length > 0"
      class="card-line"
    >
      <hit-entity-list
        :entities-to-display="item.tags"
        :use-minimum-height="false"
      />
    </div>
    <div
      v-if="showCustomColumn"
      class="card-line text-xs"
    >
      <slot
        name="customColumnContent"
        :form-data="item"
      />
    </div>
  </hit-card>
</template>
<script>
import {HitCard} from '../card';
import HitEntityList from '../list/HitEntityList.vue';
import {HitUtils} from '../../utils/hit/HitUtils';
export default {
  name: 'HitTableDataListCard',
  components: {HitCard, HitEntityList},
  props: {
    //can contain the fields zone1Field, zone1CustomFunction, zone2Field, ....
    dataListConfig: {
      type: Object,
      required: false,
      default: null,
    },
    item: {
      type: Object,
      required: true,
    },
    secondZoneBold: {
      type: Boolean,
      required: false,
      default: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    zone1Value() {
      if (this.dataListConfig) {
        if (this.dataListConfig.zone1CustomFunction) {
          return this.dataListConfig.zone1CustomFunction(this.item);
        } else if (this.dataListConfig.zone1Field) {
          return this.item[this.dataListConfig.zone1Field];
        } else {
          return undefined;
        }
      }
      return null;
    },
    zone2Value() {
      if (this.dataListConfig) {
        if (this.dataListConfig.zone2CustomFunction) {
          return this.dataListConfig.zone2CustomFunction(this.item);
        } else if (this.dataListConfig.zone2Field) {
          return this.item[this.dataListConfig.zone2Field];
        }
        //only use default value on undefined, dont show if field === null
        else if (this.dataListConfig.zone2Field === undefined) {
          return HitUtils.createStandardDesignation(this.item, 'number');
        }
      }
      return null;
    },
    zone3Value() {
      if (this.dataListConfig) {
        if (this.dataListConfig.zone3CustomFunction) {
          return this.dataListConfig.zone3CustomFunction(this.item);
        } else {
          return this.item[this.dataListConfig.zone3Field];
        }
      }
      return null;
    },
    showZone1() {
      return this.zone1Value || !!this.$slots.dataListZone1;
    },
    showZone2() {
      return this.zone2Value || !!this.$slots.dataListZone2;
    },
    showZone3() {
      return this.zone3Value || !!this.$slots.dataListZone3;
    },
    showCustomColumn() {
      return !!this.$slots.customColumnContent;
    },
  },
};
</script>
<style scoped>
.card-line {
  white-space: pre-wrap;
  @apply mt-2 ml-2 mr-2;
}
</style>
